<template>
    <div class="osWarp" :class="{'paddingBotton70':isFromApp}">
        <div class="bannerImg">
            <img v-lazy="imgUrl" />
        </div>
        <div class="content">
            <van-field label="站长" :value="siteIfo.masterName" readonly input-align="right"/>
            <van-field label="站点地址" label-width="150px" :value="siteIfo.stationLocation" readonly input-align="right"/>
            <van-field label="站点编号" label-width="150px" :value="siteIfo.stationCode" readonly input-align="right"/>
        </div>
        <div class="detail">
            <h4>{{siteIfo.contentName }}</h4>
            <div  v-html="siteIfo.content" class="ql-editor" >
            </div>
        </div>
        <div class="publicBtnDiv" v-if="isFromApp">
            <div class="publicBtnActive" @click="toAppShare">分享</div>
        </div>

    </div>

</template>

<script>
    import {getOutSStationDetails} from "../../utils/api";
    import { newsImgBaseUrl } from "../../utils/api";
    import common from "../../utils/common";
    import {appAction, shareAction, uwStatisticAction} from "../../utils/util";
    import {APP_SHARE_WX} from "../../utils/constantNum";
    import {getDecryptByBase64,getEncryptToBase64} from "../../router/utils/encryption";
    export default {
        name: "outStandSiteDetail",
        data(){
            return{
                siteIfo:{},
                imgUrl:'',
                shareDesc:'村口壹站优秀助农金融服务站',
                shareTitle:'',
                isFromApp:false
            }
        },
        filters:{
            imgBaseUrlFormat(val){
                let v= val.replace('fileTransferPath',newsImgBaseUrl)
                return v
            }
        },
        mounted() {
            this.isFromApp = common.getItem('jumpFrom') && this.$route.query.sharewx
            uwStatisticAction('/outstandSiteDetail','优质站点详情')
            let id =''
            if (common.getItem('jumpFrom') && common.getItem('ossID')){
                id = common.getItem('ossID')
            }
            if (this.$route.query.id){
               id = this.$route.query.id
            }
            let p = {
                id
            }
            getOutSStationDetails(p).then(res=>{
                if (res.code === 200 && res.data.businessCode === 200){
                    this.siteIfo = res.data.data
                    this.imgUrl =res.data.data.storefrontSignboard.replace('fileTransferPath',newsImgBaseUrl)
                    this.shareTitle = this.siteIfo.stationName
                    shareAction(this.shareTitle,this.shareDesc,this.imgUrl)
                }

            })


        },
        methods:{
            toAppShare(){
                let str = getDecryptByBase64(window.location.href.split('?')[1])
                let ossID = str.split('id=')[1].split('&jumpFrom=')[0]
                let shareUrl = window.location.origin + window.location.pathname +'?' + getEncryptToBase64('id='+ossID)
                let p = {
                    contentType:1,
                    title:this.shareTitle,
                    description:this.shareDesc,
                    url:shareUrl,
                    thumbUrl:this.imgUrl
                }
                console.log("传给app的参数：", shareUrl)
                appAction(APP_SHARE_WX,p)

            }
        }
    }
</script>

<style scoped lang="less">
    .paddingBotton70{
        padding-bottom: 70px;
    }
    .bannerImg{
        padding: 10px;
        width: 100%;
        img{
            width: 100%;
            border-radius: 8px;
        }
    }
    .detail{
        padding: 15px;
        background: #f7f8f9;
        min-height:  260px;

        h4{

            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1E1E1E;
            margin: 15px 0;
        }
    }
    .ql-editor{
        padding: 0;
        h1,h2,h3,h4,h5{
            font-size: 18px;
            font-weight: bold;
        }

    }
    .van-cell::after {

        border-bottom: 0.02rem solid #f5f5f5;

    }

</style>
<style>
    .osWarp .van-field__label{
        max-width: 90px;
    }
</style>
